import {Component, OnInit, Inject, Injector} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DialogData} from '../../../../pojos/DialogData';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MasterBean} from '../../../../utility/MasterBean';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ChangePasswordProvider} from './change-password.provider';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [ChangePasswordProvider]
})
export class ChangePasswordComponent extends MasterBean implements OnInit {
    
    oldPass: string;
    newPass: string;
    conformPass: string;

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        public dialog: MatDialog,
        public router: Router,
        public http: HttpClient,
        public injector: Injector,
        public provider: ChangePasswordProvider,
    ) {
        super(injector, router, provider);
    }
    
    ngOnInit() {
        var data={
            uid: this.auth.getSession().id_mongo,
            oprId: this.auth.getSession().OPR_ID,
            roleId: this.auth.getSession().app_role_id
        }
        JSON.stringify(data);
    }
    
    changePassword()
    {
        if (this.newPass == this.conformPass)
        {
            var oldPass = window.btoa(this.oldPass);
            var reqJsonSting = {
                uid: this.auth.getSession().id_mongo,
                newPass: this.newPass,
                oldPass: oldPass
            }
            this.provider.changePassword(JSON.stringify(reqJsonSting)).subscribe((responce: any) =>
            {
                this.util.toastSuccess(responce.message).then(done =>
                {
                    this.dialogRef.close();
                    localStorage.removeItem('isLoggedin');
                    localStorage.clear();
                    this.router.navigate(['/login']);
                });
            }, error =>
            {
                this.util.toastError(error.error.message);
            })
        }
        else
        {
            this.util.toastError("Password can't match");
        }
    }
    
    showError(errorMessage: string, firstButtonText: string)
    {
        let dialogBoxSettings = {
            width : '400px',
            disableClose : true,
            hasBackdrop: true,
            margin : '0, auto',
            data: {alertMessage: errorMessage, firstButtonText: firstButtonText}
        }
        this.dialog.open(ConfirmationDialogComponent, dialogBoxSettings);
    }
    
    public onNewClickedAction(event?) {
        
    }
    public onNewSaveAction(event?) {
        
    }
    public onModifyClickedAction(event: any) {
        
    }
    public onUpdateSaveAction(event: any) {
        
    }
    public onRemoveClickedAction(event?) {
        
    }
    public onDeleteSaveAction(event?) {
        
    }
    public onViewClickedAction(event?) {
        
    }
    public onUploadClickedAction(event?) {
        
    }
    public formSaveMode() {
        
    }
    public formUpdateMode() {
        
    }
    public formDeleteMode() {
        
    }
    public formViewMode() {
        
    }
    public formListMod() {
        
    }
    

}
