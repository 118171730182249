/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatCardModule, MatTableModule, MatIconModule, MatButtonModule, MatPaginatorModule, MatProgressBarModule, MatListModule, MatGridListModule, MatCheckboxModule, MatRadioModule, MatProgressSpinnerModule, MatAutocompleteModule, MatMenuModule, MatChipsModule, MatSortModule, MatExpansionModule, MatTabsModule, MatSlideToggleModule, MatSnackBarModule, MatTooltipModule, MatToolbarModule} from '@angular/material';
import {MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PageHeaderModule, buttonModule} from '../shared';
import {ImageUploadModule} from '../shared/modules/imageuploader';


@NgModule({
    imports:
        [CommonModule,
            FormsModule,
            ReactiveFormsModule,
            MatSelectModule,
            MatInputModule,
            MatFormFieldModule,
            MatDatepickerModule,
            MatNativeDateModule,
            PageHeaderModule,
            MatDialogModule,
            MatCardModule,
            MatTableModule,
            MatIconModule,
            MatButtonModule,
            MatPaginatorModule,
            MatProgressBarModule,
            MatListModule,
            MatGridListModule,
            MatCheckboxModule,
            MatRadioModule,
            MatProgressSpinnerModule,
            MatAutocompleteModule,
            buttonModule, ImageUploadModule,
            MatMenuModule,
            MatChipsModule,
            MatSortModule,
            MatExpansionModule,
            MatTabsModule,
            MatToolbarModule,
            MatTooltipModule,
            MatSnackBarModule,
            MatSlideToggleModule
        ],
    exports:
        [CommonModule,
            FormsModule,
            ReactiveFormsModule,
            MatSelectModule,
            MatInputModule,
            MatFormFieldModule,
            MatDatepickerModule,
            MatNativeDateModule,
            PageHeaderModule,
            MatDialogModule,
            MatCardModule,
            MatTableModule,
            MatIconModule,
            MatButtonModule,
            MatPaginatorModule,
            MatProgressBarModule,
            MatListModule,
            MatGridListModule,
            MatCheckboxModule,
            MatRadioModule,
            MatProgressSpinnerModule,
            MatAutocompleteModule,
            MatTabsModule,
            buttonModule, ImageUploadModule,
            MatMenuModule,
            MatChipsModule,
            MatSortModule,
            MatExpansionModule,
            MatToolbarModule,
            MatTooltipModule,
            MatSnackBarModule,
            MatSlideToggleModule

        ],

    declarations: [],
    providers: []
})
export class AngularMaterialModule {}


//dsssfsdfdfgdgdfgdgfggfggfdgf
