import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {AuthService} from './utility/auth-service';
import {MasterProvider} from './utility/MasterProvider';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    title = 'angular-idle-timeout';
    constructor(private idle: Idle, private keepalive: Keepalive,public dialog: MatDialog, private router: Router, private auth: AuthService,public provider:MasterProvider) {
        console.log(this.auth.getSession());
                if (this.auth.getSession() == undefined && this.auth.getSession() ==null  ) {
            localStorage.removeItem('isLoggedin');
            localStorage.clear();
            this.router.navigate(['/login']);
        }else{
        //        console.log(this.auth.getSession().email,);
        // sets an idle timeout of 5 seconds, for testing purposes.
//        idle.setIdle(600);
        idle.setIdle(1200);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(5);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
           this.idleState = 'No longer idle.'
           console.log(this.idleState);
           this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            console.log(this.idleState);
            this.dialog.closeAll();
            this.router.navigate(['/login']);
            localStorage.removeItem('isLoggedin');
            localStorage.clear();
        });

        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
            console.log(this.idleState);
            //        this.childModal.show();
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!'
            console.log(this.idleState);
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());


        this.reset();
        }
    }
    ngOnInit() {
        console.log( );
//        this.getSubscriptionData();
    }
    reset() {
        console.log("HIII");
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }
    
    getSubscriptionData(){
        this.provider.doHttpPost("/api/auth/subscriptionDetail",this.auth.getSession().id_mongo,true).subscribe((data: any) => {
            console.log(data);
            this.provider.expireSubcription=data.isExpire;
            this.provider.remainDays=data.remainingDays;
        })
    }
    
}
