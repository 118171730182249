import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {StatProvider} from './stat.provider';
import {MatDialog} from '@angular/material';
import {SiUtil} from '../../../utility/SiUtil';

@Component({
    selector: 'app-stat',
    templateUrl: './stat.component.html',
    styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {
    component: any;
    @Input() bgClass: string;
    @Input() icon: string;
    @Input() count: number;
    @Input() label: string;
    @Input() data: number;
    @Output() event: EventEmitter<any> = new EventEmitter();

    constructor(private util:SiUtil,public provider: StatProvider, public dialog: MatDialog) {}

    ngOnInit() {
    }
    
}
