import {Injectable} from "@angular/core";

/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

@Injectable()
export class PopOverService
{
    
}