import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatComponent } from './stat.component';
import {StatProvider} from './stat.provider';

@NgModule({
    imports: [CommonModule],
    declarations: [StatComponent],
     exports: [StatComponent],
    providers: [StatProvider],
//    entryComponents: [VieworderPageComponent]
})
export class StatModule {}
