import {CommonModule} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpModule} from '@angular/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuard, buttonModule} from './shared';
import {AuthService} from './utility/auth-service';
import {NotifyService} from './utility/NotifyService';
import {ResourceBundle} from './utility/ResourceBundle';
import {SiUtil} from './utility/SiUtil';
import {ConfirmationDialogComponent} from './layout/mycomponents/shared/confirmation-dialog/confirmation-dialog.component';
import {ForgotPasswordComponent} from './layout/mycomponents/shared/forgot-password/forgot-password.component';
import {FormsModule} from '@angular/forms';
import {CookieService, CookieModule} from 'ngx-cookie';
import {PopOverModule} from './shared/modules/popover/popover.module';
import {ChangePasswordComponent} from './layout/mycomponents/shared/change-password/change-password.component';
import {ImageUploadModule} from './shared/modules/imageuploader';
import {DatePipe} from '@angular/common';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import { MatSliderModule } from '@angular/material/slider';
import {MatButtonModule, MatMenuModule} from '@angular/material';
import {Setting_GeneratorComponent} from './layout/admin/setting_generator/setting_generator.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import { MasterProvider } from './utility/MasterProvider';


//import { BnNgIdleService } from 'bn-ng-idle'; 


// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  };

@NgModule({
    imports: [
    MatButtonModule, MatMenuModule,
        AppRoutingModule,
        CommonModule,
         MatSliderModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        CookieModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
              }
        }),
        buttonModule.forRoot(),
        PopOverModule.forRoot(),
        ImageUploadModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),

    ],

    declarations: [AppComponent, ConfirmationDialogComponent, ForgotPasswordComponent, ChangePasswordComponent],
    providers: [AuthGuard, AuthService, NotifyService, ResourceBundle, SiUtil, DatePipe,NgxImageCompressService],
    bootstrap: [AppComponent],
    entryComponents: [ConfirmationDialogComponent, ForgotPasswordComponent, ChangePasswordComponent],
})
export class AppModule {}
