import {Component, OnInit, Inject, Injector} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DialogData} from '../../../../pojos/DialogData';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MasterBean} from '../../../../utility/MasterBean';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ForgotPasswordProvider} from './forgot-password.provider';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    providers: [ForgotPasswordProvider]
})
export class ForgotPasswordComponent extends MasterBean implements OnInit {
    
    resetUserMail: string;

    constructor(
        public dialogRef: MatDialogRef<ForgotPasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        public dialog: MatDialog,
        public router: Router,
        public http: HttpClient,
        public injector: Injector,
        public provider: ForgotPasswordProvider,
    ) {
        super(injector, router, provider);
    }
    
    ngOnInit() {
    }
    
    forgotPassword(email: string)
    
    {
        console.log(email);
        if (email)
        {
            this.provider.forgotPassword(email).subscribe((responce: any) =>
            {
                this.util.toastSuccess(responce.message).then(done =>
                {
                    this.dialogRef.close();
                });
            }, error =>
            {
                this.util.toastError(error.error.message);
            })
        }
        else
        {
            this.util.toastError("Enter valid email id");
        }
    }
    
    showError(errorMessage: string, firstButtonText: string)
    {
        let dialogBoxSettings = {
            width : '400px',
            disableClose : true,
            hasBackdrop: true,
            margin : '0, auto',
            data: {alertMessage: errorMessage, firstButtonText: firstButtonText}
        }
        this.dialog.open(ConfirmationDialogComponent, dialogBoxSettings);
    }
    
    public onNewClickedAction(event?) {
        
    }
    public onNewSaveAction(event?) {
        
    }
    public onModifyClickedAction(event: any) {
        
    }
    public onUpdateSaveAction(event: any) {
        
    }
    public onRemoveClickedAction(event?) {
        
    }
    public onDeleteSaveAction(event?) {
        
    }
    public onViewClickedAction(event?) {
        
    }
    public onUploadClickedAction(event?) {
        
    }
    public formSaveMode() {
        
    }
    public formUpdateMode() {
        
    }
    public formDeleteMode() {
        
    }
    public formViewMode() {
        
    }
    public formListMod() {
        
    }
    

}
