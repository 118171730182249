import { HttpResponse } from '@angular/common/http';

export class FileHolder {
  public pending = false;
  public fileName :string="";
  public fileURL :string="";
  public progress :number=0;
//  public serverResponse: { status: number, response: HttpResponse<any> };

  constructor(public src: string, public file: File) {
  }
}
