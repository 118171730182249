import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from './button.component';
import {ModuleWithProviders} from '@angular/core';
import {ButtonbarService} from './ButtonbarService';

@NgModule({
    imports: [CommonModule],
    declarations: [ButtonComponent],
     exports: [ButtonComponent],
//    providers: [],
//    entryComponents: [VieworderPageComponent]
})
export class buttonModule {
    static forRoot(): ModuleWithProviders 
        {
    return {
      ngModule: buttonModule,
      providers: [ButtonbarService]
    };
  }
}
