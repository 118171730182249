import {SiUtil} from "./SiUtil";
import {HttpClient} from "@angular/common/http";
import {Injector} from "@angular/core";
import {AuthService, User} from '../utility/auth-service';
import {HttpHeaders} from "@angular/common/http";
import {LazyLoadRequest} from "../request/LazyLoadRequest";
import {HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/internal/Observable";
import {HttpEvent} from "@angular/common/http";
import {HttpRequest} from "@angular/common/http";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as fs from 'file-saver';
import Decimal from "decimal.js";
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
  
@Injectable({
    providedIn:"root"
})
export abstract class MasterProvider {

    EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    EXCEL_EXTENSION = '.xlsx';
    util: SiUtil;
    globalUser: User;
    expireSubcription: boolean=false;
    remainDays: number;
    public pageSize: number =     10;
    globalServerPath:string="";
//   globalServerPath: string = "http://65.0.104.98:808    1";
//     globalServerPath: string = "http://192.168.1.21:8081";

    //    ???????????????????????????????????????????????????????????
      //globalServerPath: string = "http://localhost:8080";     
        //globalServerPath: string = "http:// 190.92.175.111:8083";     
        // globalServerPath: string = "http://192.168.29.142:8083";     
//           globalServerPath: string = "http://13.232.164.85:8083"; 
    // globalServerPath: string = "https://entrylogs.in";
//        globalServerPath: string = "http://192.168.2.109:8080";
    public injectorObj = Injector.create({
        providers: [
            {provide: SiUtil, deps: []},

        ]
    });
    constructor(public injector: Injector, public http: HttpClient, public authService: AuthService) {
        this.util = this.injectorObj.get(SiUtil);
        this.globalUser = this.getSession();
    }


    getSession() {
        try {
            if (this.authService.getSession()) {
                this.globalUser = this.authService.getSession();
                return this.globalUser;
            }
            else {
                console.log("Session Not Inited....");
            }

        } catch (e) {
            console.log(e);
        }

    }




    public abstract save(obj: any);
    public abstract update(obj: any);
    public abstract findById(objId: any);
    public abstract findAll();
    public abstract deleteById(obj: any);
    public abstract defunctById(obj: any);



    doHttpPost(servicePath: string, param: any, authentication: boolean) {
        if (authentication) {

            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
            return this.http.post(this.globalServerPath + servicePath, param, {headers: headers});
        }
        else {
            console.log("POST METHOD: ", this.globalServerPath + servicePath);
            console.log("POST PARAM: ", param);

            return this.http.post(this.globalServerPath + servicePath, param);
        }

    }
    
      doHttpPostFileAndData(servicePath: string, param: any, authentication: boolean) {
        if (authentication) {

            let headers: HttpHeaders = new HttpHeaders();
//            headers = headers.append('Content-Type', 'multipart/form-data');
            headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
            return this.http.post(this.globalServerPath + servicePath, param, {headers: headers});
        }
        else {
            console.log("POST METHOD: ", this.globalServerPath + servicePath);
            console.log("POST PARAM: ", param);

            return this.http.post(this.globalServerPath + servicePath, param);
        }

    }
    
    
    doHttpPutPromised(servicePath: string, param: any, authentication: boolean) {

        return new Promise((resolve, reject) => {
            this.util.toastConfirmationUpdate().then(result => {
                if (result) {


                    if (authentication) {

                        let headers: HttpHeaders = new HttpHeaders();
                        headers = headers.append('Content-Type', 'application/json');
                        headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
                        return this.http.post(this.globalServerPath + servicePath, param, {headers: headers}).subscribe(result => {
//                            this.util.toastSuccess("Record Update Successfully.");
                            resolve(result);
                        }, error => {
                            this.util.toastError("Abort Transaction.");
                            console.log(error);
                            reject(error);
                        })
                    }
                    else {
                        console.log("POST METHOD: ", this.globalServerPath + servicePath);
                        console.log("POST PARAM: ", param);

                        return this.http.post(this.globalServerPath + servicePath, param).subscribe(result => {
//                            this.util.toastSuccess("Record Update Successfully.");
                            resolve(result);
                        }, error => {
//                            this.util.toastError("Abort Transaction.");
                            console.log(error);
                            reject(error);
                        })
                    }
                } else {
                    console.log("no Action ");
                    reject("cancel");
                }

            })

        })

    }
    doHttpPostPromised(servicePath: string, param: any, authentication: boolean) {

        return new Promise((resolve, reject) => {
            this.util.toastConfirmationSave().then(result => {
                if (result) {


                    if (authentication) {

                        let headers: HttpHeaders = new HttpHeaders();
                        headers = headers.append('Content-Type', 'application/json');
                        headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
                        return this.http.post(this.globalServerPath + servicePath, param, {headers: headers}).subscribe(result => {
//                            this.util.toastSuccess("Record Saved Successfully.");
                            resolve(result);
                        }, error => {
//                            this.util.toastError("Abort Transaction.");
                            console.log(error);
                            reject(error);
                        })
                    }
                    else {
                        console.log("POST METHOD: ", this.globalServerPath + servicePath);
                        console.log("POST PARAM: ", param);

                        return this.http.post(this.globalServerPath + servicePath, param).subscribe(result => {
//                            this.util.toastSuccess("Record Saved Successfully.");
                            resolve(result);
                        }, error => {
//                            this.util.toastError("Abort Transaction.");
                            console.log(error);
                            reject(error);
                        })
                    }
                } else {
                    console.log("no Action ");
                    reject("cancel");
                }

            })

        })

    }
    doHttpDeletePromised(servicePath: string, param: any, authentication: boolean) {

        return new Promise((resolve, reject) => {
            this.util.toastConfirmationDelete().then(result => {
                if (result) {


                    if (authentication) {

                        let headers: HttpHeaders = new HttpHeaders();
                        headers = headers.append('Content-Type', 'application/json');
                        headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
                        return this.http.post(this.globalServerPath + servicePath, param, {headers: headers}).subscribe(result => {
//                            this.util.toastSuccess("Record Delete Successfully.");
                            resolve(result);
                        }, error => {
//                            this.util.toastError("Abort Transaction.");
                            console.log(error);
                            reject(error);
                        })
                    }
                    else {
                        console.log("POST METHOD: ", this.globalServerPath + servicePath);
                        console.log("POST PARAM: ", param);

                        return this.http.post(this.globalServerPath + servicePath, param).subscribe(result => {
//                            this.util.toastSuccess("Record Delete Successfully.");
                            resolve(result);
                        }, error => {
//                            this.util.toastError("Abort Transaction.");
                            console.log(error);
                            reject(error);
                        })
                    }
                } else {
                    console.log("no Action ");
                    reject("cancel");
                }

            })

        })

    }

    //  http://192.168.1.15:8080/api/AdRolePage/getSinglePageObj/{"userRole":"ROL1","pageUrl":"/quotation/grade"}
    //  http://192.168.1.15:8080/api/AdRolePage/getPages/{"orgId":"1","oprId":"1","roleId":"ROL1"}
    doHttpGet(servicePath: string, authentication: boolean, param?: any) {

        if (authentication) {
            if (this.globalUser) {
                let headers: HttpHeaders = new HttpHeaders();
                headers = headers.append('Content-Type', 'application/json');
                headers = headers.append('Authorization', this.globalUser.token_Type + " " + this.globalUser.token);
                if (param) {
                    console.log("GET METHOD :" + this.globalServerPath + servicePath + "/" + JSON.stringify(param));
                    return this.http.get(this.globalServerPath + servicePath + "/" + param, {headers: headers});
                }
                else {
                    console.log("GET METHOD :" + this.globalServerPath + servicePath);
                    return this.http.get(this.globalServerPath + servicePath, {headers: headers});
                }
            }
        }
        else {
            if (param) {

                console.log("GET METHOD :" + this.globalServerPath + servicePath + "/" + JSON.stringify(param));
                return this.http.get(this.globalServerPath + servicePath + "/" + param);
            }
            else {
                console.log("GET METHOD :" + this.globalServerPath + servicePath);
                return this.http.get(this.globalServerPath + servicePath);
            }
        }
    }

    doHttpGetLazyList(servicePath: string, authentication: boolean, pageNumber: any, pageSize: any, param?: any) {

        if (authentication) {
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            headers = headers.append('Authorization', this.globalUser.token_Type + " " + this.globalUser.token);
            if (param) {
                console.log("GET METHOD :" + this.globalServerPath + servicePath + "/" + JSON.stringify(param));
                return this.http.get(this.globalServerPath + servicePath + "/" + pageSize + "," + pageNumber + param, {headers: headers});
            }
            else {
                console.log("GET METHOD :" + this.globalServerPath + servicePath);
                return this.http.get(this.globalServerPath + servicePath + "/" + pageSize + "," + pageNumber, {headers: headers});
            }
        }
        else {
            if (param) {

                console.log("GET METHOD :" + this.globalServerPath + servicePath + "/" + JSON.stringify(param));
                return this.http.get(this.globalServerPath + servicePath + "/" + pageSize + "," + pageNumber + param);
            }
            else {
                console.log("GET METHOD :" + this.globalServerPath + servicePath);
                return this.http.get(this.globalServerPath + servicePath + "/" + pageSize + "," + pageNumber);
            }
        }
    }
    doHttpPostLazy(servicePath: string, authentication: boolean, param: any) {

        if (authentication) {

            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
            return this.http.post(this.globalServerPath + servicePath, param, {headers: headers});
        }
        else {
            console.log("POST METHOD: ", this.globalServerPath + servicePath);
            console.log("POST PARAM: ", param);

            return this.http.post(this.globalServerPath + servicePath, param);
            }
    }

//  doHttpGetBySingleField(servicePath:string, param:any,authenticati    on:boo    lean)
//  {
//      if (auth    entication    )
//      {
//     let headers: HttpHeaders = new Htt    pHeaders();
//     headers = headers.append('Content-Type', 'applicat    ion/json');
//     headers = headers.append('Authorization', this.globalUser.token_Type+" " + this.globalU    ser.token);
//          return this.http.get(this.globalServerPath + servicePath + "/" + param, { headers:     headers})    ;
//      }
//          else{
//          return this.http.get(this.globalServerPath + servicePath +     "/"+param)    ;
//      }
//  }


    doHttpPostBySingleField(servicePath: string, param: any, authentication: boolean) {
        console.log(servicePath);
        console.log(param);
        if (authentication) {
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            headers = headers.append('Authorization', this.globalUser.token_Type + " " + this.globalUser.token);
            return this.http.post(this.globalServerPath + servicePath + "/" + param, {headers: headers});
        }
        else {
            return this.http.post(this.globalServerPath + servicePath + "/" + param, "");
        }
    }

    getStateList() {
        var stateList: any[] = [];
        return new Promise((resolve, reject) => {
            return this.http.get("assets/json/state.json").subscribe((list: any[]) => {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].country_id == "101") {
                        stateList.push(list[i]);
                    }
                }
                resolve(stateList);
            });
        })
    }


    getCityList(stateId: string) {
        var cityList: any[] = [];
        return new Promise((resolve, reject) => {
            return this.http.get("assets/json/city.json").subscribe((list: any[]) => {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].state_id == stateId) {
                        cityList.push(list[i]);
                    }
                }
                resolve(cityList);
            });
        })
    }


    doHttpPostReport(servicePath: string, param: any, authentication: boolean) {
        if (authentication) {

            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            headers = headers.append('Authorization', this.authService.getSession().token_Type + " " + this.authService.getSession().token);
            return this.http.post(this.globalServerPath + servicePath, param, {headers: headers, responseType: "arraybuffer"});
        }
        else {
            console.log("POST METHOD: ", this.globalServerPath + servicePath);
            console.log("POST PARAM: ", param);

            return this.http.post(this.globalServerPath + servicePath, param, {responseType: "arraybuffer"});
        }

    }

    public pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const newRequest = new HttpRequest('POST', this.globalServerPath + '/api/storage/uploadFile', formData, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(newRequest);
    }
    public pushFileToStorageLogo(file: File,id:any): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('id',id)
        const newRequest = new HttpRequest('POST', this.globalServerPath + '/api/storage/uploadLogo', formData, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(newRequest);
    }
    public pushFileToStorageStamp(file: File,id:any): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('id',id)
        const newRequest = new HttpRequest('POST', this.globalServerPath + '/api/storage/uploadStamp', formData, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(newRequest);
    }
    keyboardKeyPress(event: any) {
        const pattern = /[0-9\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    doHttpPostReportMulti(servicePath: string, param: any, authentication: boolean, reportType: string) {
        if (reportType != "VIEW") {

            return this.http.post(this.globalServerPath + servicePath, param, {responseType: "arraybuffer"})
        }
        else {

            return this.http.post(this.globalServerPath + servicePath, param);
        }
    }

    exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        console.log('worksheet', worksheet);

        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        console.log(workbook.Sheets.data.A1);
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: this.EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + this.EXCEL_EXTENSION);
    }
 
    excelData(){
        
    }
    
    calculateWithDecimal(a: number, operator: string, b: number): number {
        const numA = new Decimal(a);
        const numB = new Decimal(b);

        let result: Decimal;
        switch (operator) {
            case '+':
                result = numA.plus(numB);
                break;
            case '-':
                result = numA.minus(numB);
                break;
            case '*':
                result = numA.times(numB);
                break;
            case '/':
                if (numB.isZero()) {
                    throw new Error('Division by zero is not allowed.');
                }
                result = numA.dividedBy(numB);
                break;
            default:
                throw new Error('Invalid operator.');
        }

//        return parseFloat(result.toFixed(2));
        return parseFloat(result+"");
    }

}