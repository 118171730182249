import {Component} from '@angular/core';
import {Output} from '@angular/core';
import {EventEmitter} from '@angular/core';

@Component({
    selector: 'popover-example',
    templateUrl: 'popover-example.html',
    styleUrls: ['popover-example.css'],
})
export class PopOverComponent {

    private FORM_SAVE: string = "SAVE";
    private FORM_UPDATE: string = "UPDATE";
    private FORM_DELETE: string = "DELETE";
    private FORM_VIEW: string = "VIEW";

    
    @Output() newEvt: EventEmitter<any> = new EventEmitter();
    @Output() updateEvt: EventEmitter<any> = new EventEmitter();
    @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
    @Output() viewEvt: EventEmitter<any> = new EventEmitter();
    @Output() cloneEvt: EventEmitter<any> = new EventEmitter();
    
    
    constructor()
    {
        
    }
    
    onUpdateEvtClicked()
    {
        this.updateEvt.emit(this.FORM_UPDATE);
    }
    onDeleteEvtClicked()
    {
        this.deleteEvt.emit(this.FORM_DELETE);
    }
    onViewEvtClicked()
    {
        this.viewEvt.emit(this.FORM_VIEW);
    }
    onSaveEvtClicked()
    {
        this.viewEvt.emit(this.FORM_SAVE);
    }
    
    onCloneEvtClicked(){
         this.cloneEvt.emit(this.FORM_SAVE);
    }
    
}