/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import {Injectable} from '@angular/core';
import {Verification} from './Verification';
import swal from 'sweetalert';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DatePipe} from '@angular/common';
@Injectable()
export class SiUtil {

 public MSG_TITLE:string="Are You Sure?";
 public VALIDATION_MSG:string="Please Enter All Mandatory Fields";
 public SAVE_CONFIRMATION_MSG:string="Are you sure want to save this page?";
 public CLOSE_CONFIRMATION_MSG:string="Are you sure you want to close it?";
 public SAVE_MSG:string="Your Record has been Saved Successfully";
 public UPDATE_CONFIRMATION_MSG:string="Are you sure want to update this page?";
 public UPDATE_MSG:string="Your Record has been Updated Successfully";
 public DELETE_CONFIRMATION_MSG:string="Are you sure want to delete this page?";
 public DELETE_MSG:string="Your Record has been Deleted Successfully";
 public ABORT_MSG:string="Abort Transaction";
 public INFO_ICON:string="info";
 public SUCCESS_ICON:string="success";
 public WARNING_ICON:string="warning";
 public ERROR_ICON:string="error";
 
     public ADGEN_CASH_PAYMENT: string = "DTL2";
    public ADGEN_CASH_RECEIPT: string = "DTL3";
    public ADGEN_BANK_PAYMENT: string = "DTL4";
    public ADGEN_BANK_RECEIPT: string = "DTL5";
    public ADGEN_CUSTOMER_BANK_RECEIPT: string = "DTL6";
    public ADGEN_CUSTOMER_CASH_RECEIPT: string = "DTL7";
    public ADGEN_CUSTOMER_CHEQUE_RECEIPT: string = "DTL8";
    public ADGEN_CONTRA: string = "DTL9";
    public ADGEN_IMPORT_EXCEL: string = "DTL15";
    
       
             constructor(
        public verification: Verification,
        private dialog:MatDialog,
//        private snackBar: MatSnackBar,
        public datePipe: DatePipe
        
    ) {





    }
    
    displayDialog(component:any ,mode:string, paramData?:any)
    {
           const dialogRef = this.dialog.open(component, {

            width: '60%',
            height: '85%',
//            backdropClass: 'custom-dialog-backdrop-class',
//            panelClass: 'custom-dialog-panel-class',
               data: {formMode: mode, paramObj: paramData ||null}
        });
          dialogRef.afterClosed().subscribe(result => {

            console.log('The dialog was closed');
        });

        
    }

    toastError( msg:string)
    {
        return swal("Error?", msg, "error");
    }
    
    toastInfo(heading : string, msg:string)
    {
        return swal(heading, msg, "info");
    }
    
    toastSuccess( msg:string)
    {
        return swal("Success!", msg, "success");
    }
    
    toastWarning(heading : string, msg:string)
    {
        return swal(heading, msg, "warning");
    }
    toastConfirmationUpdate()
    {
        return swal({
            title: "Update Confirmation",
            text: "Are Your Sure want to Update?",
            icon: 'question',
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: 
                {
                    cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,


                    }
                }
        })
        
    }
    toastConfirmationSave()
    {
        return swal({
            title: "Save Confirmation",
            text: "Are Your Sure want to Save?",
            icon: 'question',
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: 
                {
                    cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,


                    }
                }
        })
        
    }
     deliveryChalan()
    {
        return swal({
            title: "Change date",
            text: "Do you want to change delivery chalan date?",
            icon: 'question',
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: 
                {
                    cancel: {
                        text: "No",
                        value: false,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "Yes",
                        value: true,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,


                    }
                }
        })
        
    }
    
    toastConfirmationDelete()
    {
        return swal({
            title: "Delete Confirmation",
            text: "Are Your Sure want to delete?",
            icon: 'question',
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: 
                {
                    cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,


                    }
                }
        })
    }
    
    toastConfirmation_Save(bodyText:string, icon:string)
    {
         return new Promise((resolve, reject) =>
         {
        swal({
            title: "Are you sure?",
            text: bodyText,
            icon: icon,
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: 
                {
                    cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,


                    }
                }
        })
            .then(async status => {
                if (status) {
                    resolve (status);
                }
                else{
                    reject(null)
                }
            });
             }) 
            
    }
    
     toastCustomized(title,text)
    {
        return swal({
            title:title,
            text: text,
            icon: 'question',
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: 
                {
                    cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "swal-button",
                        closeModal: true,


                    }
                }
        })
        
    }
    
    showLoading()
    {
        return true;
    }
    
    hideLoading()
    {
        return false;
    }
//        showSnackBar(message: string, duration: number, horizontalPosition: any, verticalPosition: any, action?: any) {
//        this.snackBar.open(message, action, {
//            duration: duration,
//            horizontalPosition: horizontalPosition,
//            verticalPosition: verticalPosition
//        });
//    }
    dateTransform_yyyyMMdd(date: any) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    dateTransform_ddMMYYYY(date: any) {
        return this.datePipe.transform(date, 'dd-MM-yyyy');
    }
}