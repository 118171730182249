import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModuleWithProviders} from '@angular/core';
import {PopOverService} from './PopOverService';
import {PopOverComponent} from './popover-example';
import {AngularMaterialModule} from '../../../app-module/angular-matrial.module';

@NgModule({
    imports: [CommonModule, AngularMaterialModule],
    declarations: [PopOverComponent],
     exports: [PopOverComponent],
//    providers: [],
//    entryComponents: [VieworderPageComponent]
})
export class PopOverModule {
    static forRoot(): ModuleWithProviders 
        {
    return {
        ngModule: PopOverModule,
      providers: [PopOverService]
    };
  }
}
