import {Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MasterProvider} from "../../../../utility/MasterProvider";
import {AuthService} from "../../../../utility/auth-service";


@Injectable()
export class ForgotPasswordProvider extends MasterProvider {
    API_FOR_RESET_PASSWORD_USER = "/api/auth/forgotPassword"   //(POST)


    constructor(public injector: Injector, public http: HttpClient, public authService: AuthService) {
        super(injector, http, authService);
    }

    public save(obj: any) {
        throw new Error("Method not implemented.");
    }
    public update(obj: any) {
        throw new Error("Method not implemented.");
    }
    public findById(objId: any) {
        throw new Error("Method not implemented.");
    }
    public findAll() {
        throw new Error("Method not implemented.");
    }
    public deleteById(obj: any) {
        throw new Error("Method not implemented.");
    }
    public defunctById(obj: any) {
        throw new Error("Method not implemented.");
    }

    forgotPassword(emailId: string) {
        console.log(emailId);
        return this.doHttpPost(this.API_FOR_RESET_PASSWORD_USER, emailId, false);
    }
}


