import { Component, OnInit } from '@angular/core';
import {Input} from '@angular/core';
import {Output} from '@angular/core';
import {EventEmitter} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
    
    @Input() fMode:string="LIST";
    
    private FORM_SAVE: string = "SAVE";
    private FORM_UPDATE: string = "UPDATE";
    private FORM_DELETE: string = "DELETE";
    private FORM_VIEW: string = "VIEW";
    private FORM_LIST: string = "LIST";
    private FORM_All_IN_ONE: string = "All";
    
    @Input() buttonNew: boolean;
    @Input() buttonNewSave: boolean;
    
    @Input() buttonUpdate: boolean;
    @Input() buttonUpdateSave: boolean;
    @Input() buttonDelete: boolean;
    @Input() buttonDeleteSave: boolean=false;
    @Input() buttonView: boolean;
    @Input() buttonClear: boolean;
    @Input() buttonClose: boolean;
    
    
    @Input() lovReturnModify: boolean=false;
    @Input() lovReturnDelete: boolean=false;
     
     @Input() firebase_Input_Create_Or_Save: boolean;
     @Input() firebase_Input_Update: boolean;
     @Input() firebase_Input_Delete: boolean;
     @Input() firebase_Input_View: boolean;
//    
    @Output() buttonNewEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonNewSaveEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonUpdateEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonUpdateSaveEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonDeleteEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonDeleteSaveEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonViewEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonClearEvent: EventEmitter<any> = new EventEmitter();
    @Output() buttonCloseEvent: EventEmitter<any> = new EventEmitter();
    
  constructor() {
  
      
     }
     
//     initButonBarMode()
//     {
//         switch(this.fMode) { 
//             case this.FORM_SAVE: { 
//      //statements; 
//      break; 
//   } 
//   case constant_expression2: { 
//      //statements; 
//      break; 
//   } 
//   default: { 
//      //statements; 
//      break; 
//   } 
//} 
//     }
  
     
     initButtonOnSaveMode()
     {
//         this.onNewButtonClicked();
     }   
     

onNewButtonClicked(){
    this.onNewButtonAction();
    this.buttonNewEvent.emit("onNewButtonClicked");  
}
onNewSaveClicked(){
    this.onSaveButtonAction();
    this.buttonNewSaveEvent.emit("onNewSaveClicked");  
}

onUpdateClicked(){
    this.buttonUpdateEvent.emit("onUpdateClicked");  
}
onUpdateSaveClicked(){
    this.buttonUpdateSaveEvent.emit("onUpdateSaveClicked");  
}
onDeleteClicked(){
    this.buttonDeleteEvent.emit("onDeleteClicked");  
}
onDeleteSaveClicked(){
    this.buttonDeleteSaveEvent.emit("onDeleteSaveClicked");  
}
onViewClicked(){
    this.onViewButtonAction();
    this.buttonViewEvent.emit("onViewClicked");  
}
onClearClicked(){
    this.buttonClearEvent.emit("onClearButton");  
}
onCloseClicked(){
    this.buttonCloseEvent.emit("onCloseClicked");  
}
  ngOnInit() {
      this.initButtonBar();
  }
  
  
  initButtonBar()
  {
      this.buttonNew=false;
      this.buttonUpdateSave = false;
      this.buttonDeleteSave = false;
      this.buttonNewSave=true
      this.buttonUpdate=true
      this.buttonDelete=true
      this.buttonView=true
      this.buttonClear=true
      this.lovReturnModify=false;
      this.lovReturnDelete=false;
  }
  
  onNewButtonAction()
  {
      this.checkAutintication();
      this.buttonClear=false;
      this.buttonNew=true;
  }
  onSaveButtonAction()
  {
    
      this.checkAutintication();
      this.initButtonBar()
  }
  onViewButtonAction()
  {
    
      this.checkAutintication();
      this.initButtonBar()
  }
  
  
  checkAutintication()
  {
        if (this.firebase_Input_Create_Or_Save)
      {
          this.buttonNewSave=false;   ////button activaed
      }else{
          this.buttonNewSave=true;    //// button deactivated
      }
      if (this.firebase_Input_Update)
      {
          this.buttonUpdate=false;   ////button activaed
      }else{
          this.buttonUpdate=true;   //// button deactivated
      }
      if (this.firebase_Input_Delete)
      {
          this.buttonDelete=false;    ////button activaed
      }else{
          this.buttonDelete=true;    //// button deactivated
      }
      if (this.firebase_Input_View)
      {
          this.buttonView=false;     ////button activaed
      }else{
          this.buttonView=true;     //// button deactivated
      }
  }
  onClearButtonAction()
  {
      this.initButtonBar();
  }
  
  /////// METHODS OF FORM  SAVE MODE ====> BUTTON BAR SHOULD BE DISPLAY AS SAVE MODE //////////////////
  
  
  
  saveMode_SaveButtonAction()
  {
      this.buttonNewSaveEvent.emit("save event");
      
  }
  
  updateMode_UpdateButtonAction()
  {
      this.buttonUpdateSaveEvent.emit("save event");
      
  }
  deleteMode_DeleteButtonAction()
  {
      this.buttonDeleteSaveEvent.emit("save event");
      
  }
  
  saveMode_ClearButtonAction(){
      this.buttonClearEvent.emit("Clear form");
  }
  
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////
}
